import React, { Fragment } from 'react';
import { Footer } from '../components/Footer';

export default () => (
  <Fragment>
    <div>
      <h1>About Ookium</h1>
      <p>Ookium is a test domain for me to play on.</p>
      <button onClick={() => alert('ok')}>button</button>
    </div>
    <Footer />
  </Fragment>
);
