import React from 'react';
import { Link } from 'gatsby';

const style = {
  textAlign: 'center',
};
export const Footer = () => {
  return (
    <div style={style}>
      <Link to="/">Home</Link> | <Link to="/about/">About</Link>
    </div>
  );
};
